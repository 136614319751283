import React from 'react';

const IconLinkedin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-linkedin"
    enableBackground="new 0 0 32 32"
    height="32px"
    version="1.1"
    viewBox="0 0 32 32"
    width="32px"
    space="preserve"
  >
    <title>StackOverflow</title>
    <g id="stackoverflow_x2C__Stack_overflow" opacity="0.99">
      <g>
        <g>
          <path
            d="M25.5,29h-19C6.224,29,6,28.776,6,28.5V21H4.5C4.224,21,4,20.776,4,20.5S4.224,20,4.5,20h2     C6.776,20,7,20.224,7,20.5V28h18v-3.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v4C26,28.776,25.776,29,25.5,29z"
            fill="#263238"
          />
        </g>
        <g>
          <path
            d="M27.5,31h-23C4.224,31,4,30.776,4,30.5v-6C4,24.224,4.224,24,4.5,24S5,24.224,5,24.5V30h22v-9h-1.5     c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2c0.276,0,0.5,0.224,0.5,0.5v10C28,30.776,27.776,31,27.5,31z"
            fill="#263238"
          />
        </g>
      </g>
      <g>
        <path
          d="M22.5,26h-13C9.224,26,9,25.776,9,25.5v-2C9,23.224,9.224,23,9.5,23h10.958c0.276,0,0.5,0.224,0.5,0.5    s-0.224,0.5-0.5,0.5H10v1h12v-1.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v2C23,25.776,22.776,26,22.5,26z"
          fill="#263238"
        />
      </g>
      <g>
        <path
          d="M22.543,22.51c-0.036,0-0.072-0.004-0.108-0.012L9.742,19.684c-0.129-0.029-0.242-0.108-0.313-0.22    s-0.095-0.248-0.066-0.377l0.434-1.953c0.06-0.269,0.318-0.437,0.597-0.38l10.567,2.343c0.27,0.06,0.439,0.327,0.38,0.597    c-0.06,0.269-0.324,0.44-0.597,0.38l-10.079-2.235l-0.217,0.977l11.716,2.598l0.324-1.464c0.06-0.269,0.318-0.439,0.597-0.38    c0.27,0.06,0.439,0.327,0.38,0.597l-0.433,1.953c-0.028,0.129-0.107,0.242-0.22,0.313C22.73,22.483,22.638,22.51,22.543,22.51z"
          fill="#263238"
        />
      </g>
      <g>
        <path
          d="M23.418,19.109c-0.071,0-0.143-0.015-0.211-0.047l-11.781-5.494c-0.251-0.117-0.359-0.414-0.242-0.665    l0.845-1.813c0.056-0.12,0.157-0.213,0.282-0.258c0.125-0.046,0.262-0.04,0.382,0.017l9.743,4.543    c0.251,0.117,0.359,0.414,0.242,0.665c-0.117,0.251-0.416,0.357-0.664,0.242l-9.29-4.332l-0.423,0.906l10.875,5.071l0.635-1.359    c0.117-0.251,0.417-0.357,0.664-0.242c0.251,0.117,0.359,0.414,0.242,0.665l-0.846,1.813    C23.786,19.002,23.605,19.109,23.418,19.109z"
          fill="#263238"
        />
      </g>
      <g>
        <path
          d="M24.839,16.095c-0.115,0-0.227-0.04-0.316-0.113L14.462,7.748c-0.214-0.175-0.245-0.49-0.07-0.703    l1.267-1.548c0.084-0.103,0.205-0.168,0.337-0.181c0.129-0.015,0.264,0.026,0.366,0.111l8.262,6.762    c0.214,0.175,0.245,0.49,0.07,0.704c-0.174,0.213-0.49,0.245-0.703,0.07l-7.875-6.445l-0.634,0.774l9.287,7.601l0.95-1.161    c0.175-0.215,0.49-0.245,0.703-0.07c0.214,0.175,0.245,0.49,0.07,0.703l-1.267,1.548c-0.084,0.103-0.205,0.168-0.337,0.181    C24.872,16.094,24.855,16.095,24.839,16.095z"
          fill="#263238"
        />
      </g>
      <g>
        <path
          d="M27.139,13.434c-0.149,0-0.298-0.067-0.396-0.196L18.829,2.924c-0.081-0.105-0.116-0.238-0.1-0.37    c0.018-0.131,0.087-0.25,0.191-0.331l1.587-1.217c0.218-0.168,0.532-0.128,0.701,0.092l6.62,8.628    c0.168,0.219,0.127,0.533-0.092,0.701c-0.22,0.167-0.533,0.127-0.701-0.092L20.72,2.104l-0.793,0.608l7.305,9.521l1.189-0.913    c0.218-0.168,0.531-0.128,0.701,0.092c0.168,0.219,0.127,0.533-0.092,0.701l-1.587,1.217C27.353,13.4,27.245,13.434,27.139,13.434    z"
          fill="#263238"
        />
      </g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d="M5,22.5C5,22.775,4.775,23,4.5,23l0,0C4.225,23,4,22.775,4,22.5l0,0C4,22.225,4.225,22,4.5,22l0,0          C4.775,22,5,22.225,5,22.5L5,22.5z"
                      fill="#263238"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d="M26,22.5c0,0.275-0.225,0.5-0.5,0.5l0,0c-0.275,0-0.5-0.225-0.5-0.5l0,0c0-0.275,0.225-0.5,0.5-0.5          l0,0C25.775,22,26,22.225,26,22.5L26,22.5z"
                      fill="#263238"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IconLinkedin;
